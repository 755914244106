import { setSuspensionShow } from "actions/ui";
import classnames from "classnames";
import Catchup from "components/Icons/CatchUp";
import Clock from "components/Icons/Clock";
import OnDemand from "components/Icons/OnDemand";
import PadlockLock from "components/Icons/PadlockLock";
import Pipe from "components/Icons/Pipe";
import Play from "components/Icons/Play";
import PlayLock from "components/Icons/PlayLock";
import PlayUnlock from "components/Icons/PlayUnlock";
import RoundLive from "components/Icons/RoundLive";
import Image, { PlaceholderType } from "components/Image";
import { LinkDuo } from "components/LinkDuo";
import LogoVod from "components/LogoVod";
import ProgressBar from "components/ProgressBar";
import ThumbnailTrailer from "components/ThumbnailTrailer";
import consts from "consts/consts";
import { format } from "date-fns";
import { detect } from "detect-browser";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import { getRealSlug } from "helpers/url";
import { CapitalizeFirstLetterObject, generateDeviceId } from "helpers/utility";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import {
  TucanoActions,
  TucanoConsts,
  TucanoModels,
  TucanoSelectors,
} from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

const TRAILER_DELAY = {
  AUTOPLAY: 0,
  RELAUNCH: 20000,
};

class Slide extends Component {
  state = {
    item: null,
    timeout: 0,
    showTrailer: true,
    trailerID: null,
    isFocusedOnTrailer: true,
  };

  async fetch() {
    const { dispatch, isConnected } = this.props;
    const { trailerID } = this.state;

    if (trailerID) {
      try {
        if (isConnected) {
          await this.checkDevice();
          await this.props.dispatch(TucanoActions.loginWebPlayer());
        }
        await dispatch(TucanoActions.getSlideTrailerUrl(trailerID));
      } catch (_e) {
        // console.error("Errror => ", e);
        return;
      }
    }
  }

  async checkDevice() {
    // Get Device ID From LocalStorage
    let deviceId = getFromLocal(consts.storageKeys.device);
    let device = { id: deviceId, ...detect() };

    if (this.props.deviceAuthToken) {
      return device;
    }
    // Generate One
    deviceId =
      device.id &&
      device.id !== "" &&
      device.id !== null &&
      device.id !== undefined
        ? device.id
        : generateDeviceId();
    saveInLocal(consts.storageKeys.device, deviceId);
    // Add Device
    await this.props.dispatch(TucanoActions.addDevice(deviceId));

    if (!this.props.deviceAuthToken) {
      throw new Error("Cant Add Device");
    }

    //Get New device stored
    device = { id: deviceId, ...detect() };

    // Auth Device
    await this.props.dispatch(TucanoActions.authDevice(device));

    return device;
  }

  async componentDidMount() {
    const { asset } = this.props;

    if (consts.displayVodData) {
      if (asset && asset.flyImage) {
        await this.setState({ item: asset.flyImage });
      }
    }
  }

  async componentDidUpdate() {
    const {
      currentSlideIndex,
      dispatch,
      homeSlides,
      showTrailer,
      vodSlides,
      index,
    } = this.props;
    let sildesSource = null;

    if (showTrailer === "homeSlides" || showTrailer === "vodSlides") {
      sildesSource = showTrailer === "homeSlides" ? homeSlides : vodSlides;
      const current = sildesSource[currentSlideIndex];
      if (
        consts.trailerOnFocus &&
        index === currentSlideIndex &&
        !this.state.trailerID &&
        current &&
        current.trailerId
      ) {
        dispatch(TucanoActions.resetSlideTrailerUrl());
        const timeout = setTimeout(async () => {
          await this.fetch();
        }, TRAILER_DELAY.AUTOPLAY);
        this.setState({ timeout, trailerID: current.trailerId });
        document.addEventListener("scroll", debounce(this.handleScroll, 200));
      } else if (
        consts.trailerOnFocus &&
        index !== currentSlideIndex &&
        this.state.trailerID
      ) {
        this.setState({ trailerID: null });
      }
    }
  }

  async componentWillUnmount() {
    const { dispatch } = this.props;
    const { timeout } = this.state;
    if (consts.trailerOnFocus) {
      clearTimeout(timeout);
      await dispatch(TucanoActions.resetSlideTrailerUrl());
      await dispatch(TucanoActions.resetCurrentTrailerIdSlider());
      document.removeEventListener("scroll", this.handleScroll);
    }
  }

  handleMouseDown(e) {
    // store mouse position when click starts
    this.x = e.screenX;
  }

  handleClick(e) {
    const delta = Math.abs(e.screenX - this.x);
    if (delta > 10) {
      // If mouse moved more than threshold, ignore the click event
      e.preventDefault();
    }
    this.x = 0;
  }

  isLock() {
    const { asset, isPlayable, accountStatus } = this.props;
    if (accountStatus && accountStatus.toLowerCase() === "suspended")
      return true;
    if (!isPlayable) return false;
    if (!consts.padlocks) return false;
    if (asset.isCategory) return false;
    if (asset.isAssetTVShow && !asset.isAssetTVShowEpisode) return false;
    if (asset.getType() === "link") return false;
    if (!this.props.isConnected) return false;
    return !asset.userHasAccess;
  }

  subscriptionModal() {
    const { dispatch, accountStatus } = this.props;
    if (accountStatus && accountStatus.toLowerCase() === "suspended") {
      dispatch(setSuspensionShow());
    } else if (this.props.subscriptionModal) {
      this.props.subscriptionModal(this.props.asset);
    }
  }

  async onTrailerEnds() {
    const { dispatch } = this.props;
    if (consts.trailerOnFocus) {
      dispatch(TucanoActions.resetSlideTrailerUrl());
      const timeout = setTimeout(async () => {
        await this.fetch();
      }, TRAILER_DELAY.RELAUNCH);
      this.setState({ timeout });
    }
  }

  handleScroll = () => {
    const { isFocusedOnTrailer } = this.state;
    const { dispatch, currentSlideIndex, index, trailer } = this.props;

    if (index !== currentSlideIndex) return;

    const trailerElem =
      document.getElementById("player") ||
      document.getElementById("sliderCover");

    if (trailerElem) {
      const fraction = 0.8;
      const x = trailerElem.offsetLeft;
      const y = trailerElem.offsetTop;
      const w = trailerElem.offsetWidth;
      const h = trailerElem.offsetHeight;
      const r = x + w; //right
      const b = y + h; //bottom;
      let visibleX,
        visibleY,
        visible,
        toStopTrailer,
        timeout = 0;

      visibleX = Math.max(
        0,
        Math.min(
          w,
          window.pageXOffset + window.innerWidth - x,
          r - window.pageXOffset
        )
      );
      visibleY = Math.max(
        0,
        Math.min(
          h,
          window.pageYOffset + window.innerHeight - y,
          b - window.pageYOffset
        )
      );

      visible = (visibleX * visibleY) / (w * h);
      if (visible > fraction) {
        toStopTrailer = false;
      } else {
        toStopTrailer = true;
      }

      if (toStopTrailer && isFocusedOnTrailer === true) {
        dispatch(TucanoActions.resetSlideTrailerUrl());
      }

      if (!toStopTrailer && isFocusedOnTrailer === false && !trailer) {
        timeout = setTimeout(async () => {
          await this.fetch();
        }, TRAILER_DELAY.AUTOPLAY);
        this.setState({ timeout });
      }

      if (this.setState)
        this.setState({ isFocusedOnTrailer: toStopTrailer, timeout });
    }
  };
  render() {
    const {
      asset,
      isPlayable,
      liveEPG,
      t,
      trailer,
      showLogin,
      isTrailerPlaying,
      currentSlideIndex,
      index,
    } = this.props;
    const { item, trailerID } = this.state;
    let type, playable;
    let showChannelImage = true;
    const topRailHeigth = consts.topRailHeigth
      ? style[consts.topRailHeigth]
      : style.containerHeightC1;

    if (asset.idType) {
      if (asset.idType === 4 || asset.idType === 8 || asset.isCategory) {
        if (asset.menuOrder) {
          if (
            asset.menuOrder[0] &&
            asset.menuOrder[0].idType &&
            asset.menuOrder[0].idType === 1
          ) {
            type = "catchup";
          }
          type = "vod";
        }
      }
    } else {
      if (
        asset.getType() === "asset_vod-background" ||
        asset.getType() === "category_vod-background" ||
        asset.getType() === "asset_vod-poster" ||
        asset.getType() === consts.imageTypes.movie_episode_Poster ||
        asset.getType() === consts.imageTypes.movie_episode_cover
      ) {
        type = "vod";
      }
      if (
        asset.getType() === "asset_vod" ||
        asset.getType() === consts.imageTypes.catchupCover
      ) {
        type = "catchup";
      }
    }
    if (
      asset.getType() === "channel_logo-vertical" ||
      asset.getType() === "channel_logo"
    ) {
      type = "live";
    }
    if (asset.getType() === "link") {
      type = "link";
    }
    if (asset.getType() === "EPGBroadcast") {
      type = "epgEvent";
    }
    let assetLiveId, assetLive;
    if (liveEPG) {
      assetLive = liveEPG.find((item) => {
        if (item.channelId === asset.getId()) {
          return true;
        } else {
          return undefined;
        }
      });
    }
    if (assetLive) {
      assetLiveId = assetLive.getEpgId();
    }
    let url = consts.routes.vod.detailUrl.replace(":id", asset.getId());
    if (type === "live") {
      if (assetLiveId) {
        url = consts.routes.epgAsset.url.replace(":id", assetLiveId);
      } else {
        url = consts.routes.playerlive.url.replace(":channelId", asset.getId());
      }
    }

    // for Homepage
    if (asset instanceof TucanoModels.TVShow) {
      if (asset.isTVShow() === true || asset.isSerie === true) {
        if (asset.isAssetTVShowEpisode) {
          url = consts.routes.tvshowAsset.url.replace(
            ":id",
            asset.getParentId()
          );
        } else {
          url = consts.routes.tvshowAsset.url.replace(":id", asset.getId());
        }
      }
    } else if (
      asset instanceof TucanoModels.Asset &&
      asset.isCategory &&
      asset.isCategory === true &&
      !asset.getCatchupId()
    ) {
      if (asset.isSerie) {
        url = consts.routes.tvshowAsset.url.replace(":id", asset.getId());
      } else {
        url = consts.routes.vod.detailUrl.replace(":id", asset.getId());
      }
    }

    if (asset.isCategory === false && asset.getParentId() !== undefined) {
      if (
        asset.isAssetTVShow === false &&
        asset.isAssetTVShowEpisode === true
      ) {
        url = consts.routes.tvshowAsset.url.replace(":id", asset.getParentId());
      }
    }
    if (type === "epgEvent") {
      url = consts.routes.epgAsset.url.replace(":id", asset.getEpgId());
      if (asset.isLive()) {
        playable = true;
      } else {
        playable = false;
      }
    }
    // for Vod Homepage
    if (
      asset instanceof TucanoModels.Asset &&
      !asset.isCategory &&
      asset.isAssetTVShow === true
    ) {
      if (asset.isSerie === true) {
        url = consts.routes.tvshowAsset.url.replace(":id", asset.getId());
      } else {
        url = consts.routes.vod.detailUrl.replace(":id", asset.getId());
      }
    }
    if (type === "link") {
      url = getRealSlug(asset.getUrl());
    }

    if (asset instanceof TucanoModels.Asset) {
      if (asset.isCategory === false && asset.isSerie === false) {
        url = consts.routes.movieAsset.url.replace(":id", asset.getId());
      }
    }
    let validDay = "";
    if (asset instanceof TucanoModels.Asset && asset && asset.isCatchupAsset) {
      let now = new Date();
      let assetDate = new Date(asset.validityStartDate * 1000);
      let days = Math.floor(
        (Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()) -
          Date.UTC(
            assetDate.getFullYear(),
            assetDate.getMonth(),
            assetDate.getDate()
          )) /
          (1000 * 60 * 60 * 24)
      );
      validDay =
        days >= 1
          ? days === 1
            ? "-" + days + t(" DAY")
            : "-" + days + t(" DAYS")
          : t("Today");
    }

    const logoWidth = 80;
    const logoHeight = 80;
    /*let catalog;
    if (item instanceof TucanoModels.TVShow) {
      catalog = item.getCatalogue();
    }*/
    let srcLogo;
    if (asset instanceof TucanoModels.Asset && asset.isCatchupAsset === true) {
      if (typeof asset.getProviderId() !== String) {
        showChannelImage = false;
      } else {
        srcLogo =
          consts.image_hostname +
          `${routes.imgdata}?type=${consts.imageTypes.tvChannel}&objectId=` +
          asset.getProviderId() +
          "&format_w=80&format_h=80&languageId=" +
          getFromLocal("languageId");
      }
    }
    if (asset instanceof TucanoModels.EPGEventAsset) {
      srcLogo =
        consts.image_hostname +
        `${routes.imgdata}?type=${consts.imageTypes.tvChannel}&objectId=` +
        asset.getChannelId() +
        "&format_w=80&format_h=80&languageId=" +
        getFromLocal("languageId");
    }

    const finalGradientClassName = classnames({
      [style.slideGradient]:
        consts.showSliderGradient &&
        asset.designMode !== "fullscreen-notext" &&
        type !== "link",
      [style.slideTopGradient]: this.props.showTopGradient,
    });

    return (
      <div className={classnames(style.slide)} style={{ position: "relative" }}>
        {!consts.vodFullscreenNoText &&
          asset.designMode !== "fullscreen-notext" &&
          item && (
            <div className={style.VODLogo}>
              <LogoVod className={style.vodData} type={item} size={50} />
            </div>
          )}

        {srcLogo && showChannelImage && (
          <div className={style.logoContainer}>
            <Image
              rootClassName={style.channel}
              height={logoHeight}
              width={logoWidth}
              src={srcLogo}
              placeholder={PlaceholderType.TRANSPARENT}
            />
          </div>
        )}
        {!consts.vodFullscreenNoText &&
          trailer &&
          trailer.url &&
          trailer.trailerID === trailerID &&
          !showLogin && (
            <div
              className={classnames(style.trailerContainer, {
                [style.isVideoPlaying]: isTrailerPlaying,
                [style.isVideoStopped]: !isTrailerPlaying,
              })}
            >
              {index === currentSlideIndex && trailer && (
                <>
                  <ThumbnailTrailer
                    trailerUrl={trailer.url}
                    fromSlider={true}
                    onTrailerEnds={this.onTrailerEnds.bind(this)}
                  />
                </>
              )}
              {asset.designMode !== "fullscreen-notext" && (
                <>
                  {type === "live" && (
                    <div className={style.liveContainer}>
                      <RoundLive className={style.liveIcon} />
                      <span className={style.live}>
                        {CapitalizeFirstLetterObject(<Trans>live</Trans>)}
                      </span>
                    </div>
                  )}
                  {type === "epgEvent" && asset.isLive() && (
                    <div className={style.liveContainer}>
                      <RoundLive className={style.liveIcon} />
                      <span className={style.live}>
                        {CapitalizeFirstLetterObject(<Trans>live</Trans>)}
                      </span>
                    </div>
                  )}
                  {type === "vod" && asset.isCategory === false && (
                    <div className={style.onDemandContainer}>
                      <OnDemand className={style.onDemandIcon} />
                      <span className={style.onDemand}>
                        <Trans>On demand</Trans>
                      </span>
                    </div>
                  )}
                  {asset instanceof TucanoModels.Asset &&
                    asset.isTVShow() === true && (
                      <div className={style.onDemandContainer}>
                        <OnDemand className={style.onDemandIcon} />
                        <span className={style.onDemand}>
                          <Trans>On demand</Trans>
                        </span>
                      </div>
                    )}
                  <div className={style.info}>
                    {asset.isCategory === true &&
                      asset.isTVShow() === false && (
                        <p className={style.title}>{asset.getTitle()}</p>
                      )}
                    {asset.type === consts.imageTypes.seriePoster &&
                      asset.isTVShow() === true &&
                      asset.isSerie === false && (
                        <p className={style.title}>{asset.getTitle()}</p>
                      )}
                    {asset instanceof TucanoModels.Channel &&
                      type === "live" && (
                        <p className={style.title}>{asset.getName()}</p>
                      )}
                    {asset instanceof TucanoModels.EPGEventAsset && (
                      <Fragment>
                        {asset.isLive() ? (
                          <p className={style.broadcastTime}>
                            {format(asset.getStartTime(), "HH:mm")} -{" "}
                            {format(asset.getEndTime(), "HH:mm")}
                          </p>
                        ) : (
                          <p className={style.broadcastTime}>
                            {asset.getBroadcastTime()}
                          </p>
                        )}
                        <p className={style.title}>{asset.getTitle()}</p>
                      </Fragment>
                    )}
                    {asset instanceof TucanoModels.Asset &&
                      type === "vod" &&
                      asset.isTVShow() === false &&
                      asset.isTVShowEpisode() === false &&
                      asset.isCategory === false && (
                        <Fragment>
                          <p className={style.title}>{asset.getTitle()}</p>
                          <p className={style.programType}>
                            <Trans>MOVIE</Trans>
                            {asset.getHumanDuration() && (
                              <span className={style.duration}>
                                <Pipe className={style.pipeIcon} />
                                <Clock className={style.durationIcon} />
                                {asset.getHumanDuration()}
                              </span>
                            )}
                          </p>
                        </Fragment>
                      )}
                    {(asset instanceof TucanoModels.Asset ||
                      asset instanceof TucanoModels.TVShow) && (
                      <>
                        {type === "vod" &&
                          asset.isTVShow() === false &&
                          asset.isTVShowEpisode() === true &&
                          asset.isCategory === false && (
                            <Fragment>
                              <p className={style.title}>{asset.getTitle()}</p>
                              <p className={style.programType}>
                                <Trans>Season</Trans> {asset.getSeasonNumber()}
                                <Pipe className={style.pipeIcon} />
                                <Trans>Episode</Trans>{" "}
                                {asset.getEpisodeNumber()}
                              </p>
                            </Fragment>
                          )}
                        {asset.isSerie === true && (
                          <Fragment>
                            <p className={style.title}>{asset.getTitle()}</p>
                            <p className={style.programType}>
                              <Trans>SERIES</Trans>
                            </p>
                          </Fragment>
                        )}
                      </>
                    )}
                  </div>

                  {!consts.vodFullscreenNoText &&
                    asset.designMode !== "fullscreen-notext" &&
                    type !== "link" && (
                      <LinkDuo to={url} onClick={this.handleClick.bind(this)}>
                        <button className={style.button}>
                          <Trans>See More</Trans>
                        </button>
                      </LinkDuo>
                    )}
                </>
              )}
            </div>
          )}
        {type !== "link" &&
          !(
            trailer &&
            trailer.url &&
            trailer.trailerID === trailerID &&
            !showLogin
          ) && (
            <LinkDuo
              className={finalGradientClassName}
              to={url}
              onClick={this.handleClick.bind(this)}
            >
              <div
                id="sliderCover"
                className={classnames(
                  {
                    [style.isVideoPlaying]: isTrailerPlaying,
                    [style.isVideoStopped]: !isTrailerPlaying,
                  },
                  style.poster,
                  style.container,
                  topRailHeigth
                )}
                style={{
                  backgroundImage: `url(${asset.getPosterImage(
                    consts.image_hostname,
                    {
                      width: 1920,
                      height: 1080,
                      languageId: getFromLocal("languageId"),
                    }
                  )}`,
                  opacity: 1,
                }}
                onMouseDown={this.handleMouseDown.bind(this)}
              >
                {!consts.vodFullscreenNoText &&
                  asset.designMode !== "fullscreen-notext" &&
                  type === "live" && (
                    <div className={style.liveContainer}>
                      <RoundLive className={style.liveIcon} />
                      <span className={style.live}>
                        {CapitalizeFirstLetterObject(<Trans>live</Trans>)}
                      </span>
                    </div>
                  )}
                {!consts.vodFullscreenNoText &&
                  asset.designMode !== "fullscreen-notext" &&
                  type === "epgEvent" &&
                  asset.isLive() && (
                    <div className={style.liveContainer}>
                      <RoundLive className={style.liveIcon} />
                      <span className={style.live}>
                        {CapitalizeFirstLetterObject(<Trans>live</Trans>)}
                      </span>
                    </div>
                  )}
                {!consts.vodFullscreenNoText &&
                  asset.designMode !== "fullscreen-notext" &&
                  type === "vod" &&
                  asset.isCategory === false && (
                    <div className={style.onDemandContainer}>
                      <OnDemand className={style.onDemandIcon} />
                      <span className={style.onDemand}>
                        <Trans>On demand</Trans>
                      </span>
                    </div>
                  )}
                {!consts.vodFullscreenNoText &&
                  asset.designMode !== "fullscreen-notext" &&
                  asset instanceof TucanoModels.Asset &&
                  asset.isTVShow() === true && (
                    <div className={style.onDemandContainer}>
                      <OnDemand className={style.onDemandIcon} />
                      <span className={style.onDemand}>
                        <Trans>On demand</Trans>
                      </span>
                    </div>
                  )}
                {!consts.vodFullscreenNoText &&
                  asset.designMode !== "fullscreen-notext" && (
                    <div className={style.info}>
                      {asset.isCategory === true &&
                        asset.isTVShow() === false && (
                          <p className={style.title}>{asset.getTitle()}</p>
                        )}
                      {asset.type === consts.imageTypes.seriePoster &&
                        asset.isTVShow() === true &&
                        asset.isSerie === false && (
                          <p className={style.title}>{asset.getTitle()}</p>
                        )}
                      {asset instanceof TucanoModels.Channel &&
                        type === "live" && (
                          <p className={style.title}>{asset.getName()}</p>
                        )}
                      {asset instanceof TucanoModels.EPGEventAsset && (
                        <Fragment>
                          {asset.isLive() ? (
                            <p className={style.broadcastTime}>
                              {format(asset.getStartTime(), "HH:mm")} -{" "}
                              {format(asset.getEndTime(), "HH:mm")}
                            </p>
                          ) : (
                            <p className={style.broadcastTime}>
                              {asset.getBroadcastTime()}
                            </p>
                          )}
                          <p className={style.title}>{asset.getTitle()}</p>
                        </Fragment>
                      )}
                      {asset instanceof TucanoModels.Asset &&
                        type === "vod" &&
                        asset.isTVShow() === false &&
                        asset.isTVShowEpisode() === false &&
                        asset.isCategory === false && (
                          <Fragment>
                            <p className={style.title}>{asset.getTitle()}</p>
                            <p className={style.programType}>
                              <Trans>MOVIE</Trans>
                              {asset.getHumanDuration() && (
                                <span className={style.duration}>
                                  <Pipe className={style.pipeIcon} />
                                  <Clock className={style.durationIcon} />
                                  {asset.getHumanDuration()}
                                </span>
                              )}
                            </p>
                          </Fragment>
                        )}
                      {(asset instanceof TucanoModels.Asset ||
                        asset instanceof TucanoModels.TVShow) && (
                        <>
                          {type === "vod" &&
                            asset.isTVShow() === false &&
                            asset.isTVShowEpisode() === true &&
                            asset.isCategory === false && (
                              <Fragment>
                                <p className={style.title}>
                                  {asset.getTitle()}
                                </p>
                                <p className={style.programType}>
                                  <Trans>Season</Trans>{" "}
                                  {asset.getSeasonNumber()}
                                  <Pipe className={style.pipeIcon} />
                                  <Trans>Episode</Trans>{" "}
                                  {asset.getEpisodeNumber()}
                                </p>
                              </Fragment>
                            )}
                          {asset.isSerie === true && (
                            <Fragment>
                              <p className={style.title}>{asset.getTitle()}</p>
                              <p className={style.programType}>
                                <Trans>SERIES</Trans>
                              </p>
                            </Fragment>
                          )}
                        </>
                      )}
                    </div>
                  )}
                {type === "catchup" && (
                  <div className={style.info}>
                    <span className={style.validDayContainer}>
                      <Catchup className={style.catchupIcon} />
                      <span className={style.validDay}>{validDay}</span>
                    </span>
                    <p className={style.title}>{asset.title}</p>
                    <p className={style.programType}>
                      <Trans>CATCH-UP</Trans>
                      <Pipe className={style.pipeIcon} />
                      {asset.getHumanDuration && (
                        <span className={style.duration}>
                          {/* <Pipe className={style.pipeIcon} /> */}
                          <Clock className={style.durationIcon} />
                          {asset.getHumanDuration()}
                        </span>
                      )}
                    </p>
                  </div>
                )}
                {!consts.vodFullscreenNoText &&
                  asset.designMode !== "fullscreen-notext" &&
                  type !== "link" && (
                    <button className={style.button}>
                      <Trans>See More</Trans>
                    </button>
                  )}
              </div>
            </LinkDuo>
          )}
        {type === "link" &&
          url.substring(0, 4) !== "www." &&
          !(
            trailer &&
            trailer.url &&
            trailer.trailerID === trailerID &&
            !showLogin
          ) && (
            <LinkDuo
              className={finalGradientClassName}
              to={url}
              onClick={this.handleClick.bind(this)}
            >
              <div
                className={style.container + " " + topRailHeigth}
                style={{
                  backgroundImage: `url(${asset.getImage(
                    consts.image_hostname,
                    {
                      languageId: getFromLocal("languageId"),
                      width: 1920,
                      height: 1080,
                    }
                  )}`,
                }}
                onMouseDown={this.handleMouseDown.bind(this)}
              />
            </LinkDuo>
          )}
        {type === "link" &&
          url.substring(0, 4) === "www." &&
          !(
            trailer &&
            trailer.url &&
            trailer.trailerID === trailerID &&
            !showLogin
          ) && (
            <a
              className={finalGradientClassName}
              href={`//${url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className={style.container + " " + topRailHeigth}
                style={{
                  backgroundImage: `url(${asset.getImage(
                    consts.image_hostname,
                    {
                      languageId: getFromLocal("languageId"),
                      width: 1920,
                      height: 1080,
                    }
                  )}`,
                }}
                onMouseDown={this.handleMouseDown.bind(this)}
              />
            </a>
          )}
        {asset instanceof TucanoModels.Asset &&
          isPlayable &&
          asset.isAssetTVShow !== true &&
          !asset.isCategory &&
          type !== "link" &&
          !this.isLock() && (
            <Link
              to={consts.routes.player.url.replace(":assetId", asset.getId())}
              className={style.playIcon}
            >
              <Play />
            </Link>
          )}
        {isPlayable && asset instanceof TucanoModels.Channel && !this.isLock() && (
          <Link
            to={consts.routes.playerlive.url.replace(
              ":channelId",
              asset.getId()
            )}
            className={style.playIcon}
          >
            <Play />
          </Link>
        )}
        {asset instanceof TucanoModels.EPGEventAsset &&
          asset.isLive() &&
          playable === true &&
          !this.isLock() && (
            <Link
              to={consts.routes.playerlive.url.replace(
                ":channelId",
                asset.getChannelId()
              )}
              className={style.playIcon}
            >
              <Play />
            </Link>
          )}

        {/* LOCK */}
        {this.isLock() &&
          ((isPlayable &&
            asset instanceof TucanoModels.Asset &&
            asset.isTVShow() !== true &&
            !asset.isCategory) ||
            (isPlayable && asset instanceof TucanoModels.Channel) ||
            (asset instanceof TucanoModels.EPGEventAsset &&
              asset.isLive() &&
              playable === true)) && (
            <div onClick={this.subscriptionModal.bind(this)}>
              <PlayLock
                className={classnames(style.playIcon, style.playLockIcon)}
              />
              <PlayUnlock
                className={classnames(style.playIcon, style.playUnlockIcon)}
              />
              <div className={style.padlockContainer}>
                <div className={style.padlockIconContainer}>
                  <PadlockLock className={style.padlockLockIcon} />
                </div>
              </div>
            </div>
          )}

        {!!asset.seekTime && (
          <ProgressBar
            position={asset.getProgress()}
            rootClassName={style.progressBar}
          />
        )}
        {type === "epgEvent" && asset.isLive() && asset.getEpgProgress() && (
          <ProgressBar
            position={asset.getEpgProgress()}
            rootClassName={style.progressBar}
          />
        )}
      </div>
    );
  }
}

Slide.defaultProps = {
  asset: {},
  isPlayable: false,
  isLive: false,
};

Slide.propTypes = {
  asset: PropTypes.object,
  isPlayable: PropTypes.bool,
  isLive: PropTypes.bool,
};

export default compose(
  connect((state) => {
    return {
      isConnected: state.session.customerAuthToken !== undefined,
      trailer: TucanoSelectors.getAssetSlideTrailer(state),
      deviceAuthToken: state.session.deviceAuthToken !== undefined,
      homeSlides: TucanoSelectors.getHomeSlideshow(state),
      vodSlides: TucanoSelectors.getVODSlideshow(state),
      showLogin: state.ui.loginShown,
      isTrailerPlaying: state.player.trailer.playing,
      accountStatus: state.account.user?.data?.status,
    };
  }),
  withTranslation()
)(Slide);
